import React, { Component } from 'react'
import { CircularProgress, Container } from '@material-ui/core'
import './Login.css'
import { API } from '../../utils/service'
import { notification } from 'antd'

export default class LoginWithToken extends Component {
    constructor() {
        super()
        this.state = {
            token: ''
        }
    }

    componentDidMount() {
        const { match: { params }, history } = this.props
        if (params.token) {
            this.setState({token: params.token}, () => {
                this.getToken()
            })
        } else {
            history.replace('/')
        }
    }

    getToken = () => {
        const { token } = this.state
        const { history } = this.props
        API
            .get(`api/user/login/${token}`)
            .then((res) => {
                const {data: response} = res;
                if (response.error) {
                    notification['error']({
                        message: response.message || 'Server Error. Please try again',
                        onClose: () => history.replace('/login')
                    })
                } else {
                    localStorage.setItem('AT', response.accessToken)
                    history.replace('/dashboard')
                }
            })
            .catch((err) => {
                console.log(err);
                notification['error']({
                    message: 'Something went wrong. Please try again',
                    onClose: () => history.replace('/')
                })
            });
    }

    render() {
        return (
            <div className="login-container">
                <Container maxWidth="sm" className="login-inner-container">
                    <div className="header">
                        <span className="logo">Bollycoin</span> | Admin Panel
                    </div>
                    <div className="login-section">
                        <p>You will be redirected to dashboard shortly</p>
                        <CircularProgress size={30} style={{color: '#000'}} />
                    </div>
                </Container>
            </div>
        )
    }
}
