import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './pages/login/Login';
import LoginWithToken from './pages/login/LoginWithToken';
import Home from './pages/dashboard/Home';
export default class App extends React.Component {
	render() {
		return (
			<Switch>
				<Route
					exact
					path="/"
					component={Login}
				/>
				<Route
					exact
					path="/login/:token"
					component={LoginWithToken}
				/>
				<Route
					exact
					path="/dashboard"
					component={Home}
				/>
				<Redirect to="/" />
			</Switch>
		);
	}
}