import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { CircularProgress, Container, TextField } from '@material-ui/core'
import './Login.css'
import { API } from '../../utils/service'

class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            emailError: '',
            helperText: '',
            loggingIn: false,
        }
    }

    componentDidMount() {
        const { history } = this.props
        const token = localStorage.getItem('AT')
        if (token) {
            history.replace('/dashboard')
        }
    }

    handleLogin = () => {
        let { email } = this.state;
        email = email.trim()
        if (!email) {
            this.setState({
                emailError: true,
                helperText: 'Enter your E-mail'
            })
            return
        }
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            this.setState({
                emailError: true,
                helperText: 'Enter a valid E-mail address',
            })
            return
        }
        this.setState({loggingIn: true, emailError: false, helperText: ''}, () => {
            const data = {
                email: email.toLowerCase(),
            }
            API
                .post('api/user/login/root', data)
                .then((res) => {
                    const {data: response} = res;
                    if (response.error) {
                        this.setState({
                            loggingIn: false,
                            emailError: true,
                            helperText: response.message || 'Server Error. Please try again',
                        })
                    } else {
                        this.setState({
                            loggingIn: false,
                            helperText: 'A magic link has been sent to your E-mail. Click the link to log in.'
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loggingIn: false,
                        emailError: true,
                        helperText: 'Something went wrong. Please try again',
                    })
                })
        })
    }
    
    render() {
        const { email, emailError, helperText, loggingIn } = this.state
        return (
            <div className="login-container">
                <Container maxWidth="sm" className="login-inner-container">
                    <div className="header">
                        <span className="logo">Bollycoin</span> | Admin Panel
                    </div>
                    <div className="login-section">
                        <div className="login-form-control">
                            <TextField
                                type="email"
                                label="E-mail"
                                value={email}
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value,
                                        emailError: false,
                                        helperText: ''
                                    })
                                }}
                                error={Boolean(emailError)}
                                placeholder="Enter your E-mail Address"
                                helperText={helperText}
                                variant="outlined"
                                className="login-form-input"
                                inputProps={{
                                    style: {
                                        fontFamily: 'normal'
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontFamily: 'normal'
                                    }
                                }}
                                FormHelperTextProps={{
                                    style: {
                                        fontFamily: 'normal',
                                        fontSize: '15px'
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.handleLogin()
                                    }
                                }}
                            />
                        </div>
                        <button disabled={loggingIn} onClick={this.handleLogin}>{!loggingIn ? "log in" : (
                            <CircularProgress size={17} style={{color: '#FFF', position: 'relative', top: '3px'}} />
                        )}</button>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withRouter(Login)