import axios from "axios";

export const API = axios.create({
    baseURL: 'https://api.bollycoin.net/',
})

export const isTokenValid = (token) => {
    return new Promise(async (resolve) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await API.get('api/user/validate', config)
            if (response.data.error) {
                resolve(false)
            } else {
                resolve(true)
            }
        } catch (_) {
            resolve(false)
        }
    })
}