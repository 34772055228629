import React, { Component } from 'react'
import { Modal } from 'antd'
import './WhitelistActionModal.css'
import { CircularProgress } from '@material-ui/core';

export default class WhitelistActionModal extends Component {
    render() {
        const {
			visible,
			name,
			email,
			address,
			onCancel,
			onConfirm,
			action,
			loading,
		} = this.props;
        return (
            <Modal
				className="whitelist-action-modal"
				visible={visible}
				footer={false}
				onCancel={onCancel}
				centered
			>
            	<div className="modal-container" style={{marginTop: 0}}>
					<h2>Confirm Whitelist Action</h2>
					<div className="confirm-grid">
						<div>
							<div>Name</div>
							<div>{name}</div>
						</div>
                        <div>
                            <div>Email</div>
                            <div>{email}</div>
                        </div>
                        <div>
                            <div>Wallet Address</div>
                            <a
								href={`https://kovan.etherscan.io/address/${address}`}
								target="_blank"
								rel="noopener noreferrer"
							>{`${address}`.substring(0,6) + '...' + `${address}`.substring(37,42)}</a>
                        </div>
                    </div>
					<div className="confirm-buttons-container">
                        <button className="cancel-button" onClick={onCancel}>
                            cancel
                        </button>
                        <button className="confirm-button" onClick={onConfirm}>
							{loading ? (
								<CircularProgress size={16} style={{color: '#FFF', position: 'relative', top: '2px'}} />
							) : action}
                        </button>
                    </div>
            	</div>   
            </Modal>
        )
    }
}
