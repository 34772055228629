import React, { Component } from 'react'
import { WhitelistActionModal } from '../../components/modals'
import { withRouter } from 'react-router'
import { CircularProgress, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'
import { FiLogOut, FiCheck, FiX } from 'react-icons/fi'
import { IoFilter, IoSearch, IoRefresh } from 'react-icons/io5'
import { API, isTokenValid } from '../../utils/service'
import { Dropdown, Empty, Input, Menu, notification, Select, Tag } from 'antd'
import './Home.css'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fetchingHistory: true,
            history: [],
            pseudoHistory: [],
            selectedUser: {
                id: '',
                name: '',
                email: '',
                address: '',
                action: '',
            },
            confirmActionModalVisible: false,
            loading: false,
            currentPage: 0,
            rowsPerPage: 10,
            activeFilter: 'all',
            searchKey: 'email',
            searchQuery: '',
            loggingOut: false,
        }
    }

    componentDidMount() {
        const { history } = this.props
        const token = localStorage.getItem('AT')
        if (token) {
            this.validateToken(token)
        } else {
            history.replace('/')
        }
    }

    validateToken = async (token) => {
        try {
            const isValid = await isTokenValid(token)
            if (isValid) {
                this.fetchWhitelistHistory()
            } else {
                const { history } = this.props
                localStorage.removeItem('AT')
                notification['error']({
                    message: 'Your session has been expired. Please log in again',
                    onClose: () => history.replace('/')
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    fetchWhitelistHistory = (loading = true) => {
        const { history } = this.props
        const token = localStorage.getItem('AT')
        if (token) {
            const config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            };
            this.setState({[loading ? 'fetchingHistory' : 'refreshing']: true}, () => {
                API
                    .get('api/user/activity', config)
                    .then((res) => {
                        const {data: response} = res;
                        this.setState({[loading ? 'fetchingHistory' : 'refreshing']: false}, () => {
                            if (response.error) {
                                notification['error']({
                                    message: "Couldn't fetch whitelist requests",
                                    description: response.message || 'Server error. Please reload the page',
                                })
                            } else {
                                if (response.data.length > 0) {
                                    this.setState({
                                        history: [...response.data].reverse(),
                                        pseudoHistory: [...response.data].reverse(),
                                    })
                                }
                            }
                        })
                    })
                    .catch((err) => {
                        this.setState({[loading ? 'fetchingHistory' : 'refreshing']: false}, () => {
                            console.log(err);
                            notification['error']({
                                message: "Couldn't fetch whitelist requests",
                                description: 'Something went wrong. Please reload the page',
                            })
                        })
                    });
            })
        } else {
            history.replace('/')
        }
    }

    setActiveUser = (userId, action) => {
        const selectedUser = this.state.history.filter((user) => user._id === userId).map((user) => (
            {
                id: user._id,
                name: user.name,
                email: user.email,
                address: user.address,
                action: action
            }
        ))[0]
        this.setState({
            selectedUser: {...selectedUser},
            confirmActionModalVisible: true,
        })
    }

    clearActiveUser = () => {
        this.setState({confirmActionModalVisible: false}, () => {
            setTimeout(
                () => this.setState({
                    selectedUser: {
                        id: '',
                        name: '',
                        email: '',
                        address: ''
                    }
                }),
                500
            )
        })
    }

    approveUser = () => {
        const token = localStorage.getItem('AT')
        const { selectedUser: { email, address } } = this.state
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const data = {
            address: address,
            email_id: email,
            status: 'Approved'
        };
        this.setState({loading: true}, () => {
            API
                .post('api/user/moderate', data, config)
                .then((res) => {
                    const {data: response} = res;
                    this.setState({loading: false}, () => {
                        if (response.error) {
                            notification['error']({
                                message: "Couldn't approve request",
                                description: response.message || 'Server error. Please try again',
                            })
                        } else {
                            this.clearActiveUser()
                            this.fetchWhitelistHistory(false)
                            notification['success']({
                                message: "Approved whitelist request",
                            })
                        }
                    })
                })
                .catch((err) => {
                    this.setState({loading: false}, () => {
                        console.log(err);
                        notification['error']({
                            message: "Couldn't approve request",
                            description: 'Something went wrong. Please try again later',
                        })
                    })
                });
        })
    }

    rejectUser = () => {
        const token = localStorage.getItem('AT')
        const { selectedUser: { email, address } } = this.state
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const data = {
            address: address,
            email_id: email,
            status: 'Denied'
        };
        this.setState({loading: true}, () => {
            API
                .post('api/user/moderate', data, config)
                .then((res) => {
                    const {data: response} = res;
                    this.setState({loading: false}, () => {
                        if (response.error) {
                            notification['error']({
                                message: "Couldn't deny request",
                                description: response.message || 'Server error. Please try again',
                            })
                        } else {
                            this.clearActiveUser()
                            this.fetchWhitelistHistory(false)
                            notification['success']({
                                message: "Denied whitelist request",
                            })
                        }
                    })
                })
                .catch((err) => {
                    this.setState({loading: false}, () => {
                        console.log(err);
                        notification['error']({
                            message: "Couldn't deny request",
                            description: 'Something went wrong. Please try again later',
                        })
                    })
                });
        })
    }

    handleChangePage = (_, currentPage) => this.setState({ currentPage: currentPage })

    handleChangeRowsPerPage = e => {
        this.setState({ 
            rowsPerPage: parseInt(e.target.value, 10),
            currentPage: 0
        })
    }

    handleRowLabel = ({from, to, count}) => {
        return(
            <span>
                <span>{from}</span> - <span>{to}</span> of <span>{count}</span> requests
            </span>
        )
    }

    setFilter = (filterType) => {
        const { history } = this.state
        this.setState({activeFilter: filterType, searchQuery: ''}, () => {
            switch (filterType) {
                case 'all':
                    this.setState({
                        pseudoHistory: [...history]
                    })
                    break;
                
                case 'approved':
                    const approvedRequests = history.filter((user) => user.status === 'Approved')
                    this.setState({
                        pseudoHistory: [...approvedRequests]
                    })
                    break;

                case 'pending':
                    const pendingRequests = history.filter((user) => user.status === 'Pending')
                    this.setState({
                        pseudoHistory: [...pendingRequests]
                    })
                    break;

                case 'denied':
                    const deniedRequests = history.filter((user) => user.status === 'Denied')
                    this.setState({
                        pseudoHistory: [...deniedRequests]
                    })
                    break;

                case 'whitelisted':
                    const whitelistedRequests = history.filter((user) => user.isWhitelisted)
                    this.setState({
                        pseudoHistory: [...whitelistedRequests]
                    })
                    break;

                case 'non-whitelisted':
                    const nonWhitelistedRequests = history.filter((user) => !user.isWhitelisted)
                    this.setState({
                        pseudoHistory: [...nonWhitelistedRequests]
                    })
                    break;

                default:
                    break;
            }
        })
    }

    handleSearch = (e) => {
        const { target: { value } } = e
        const { history, searchKey } = this.state;
        this.setFilter('all')
        this.setState({searchQuery: value}, () => {
            if (value) {
                let filteredData = history.filter(user => user[searchKey] && user[searchKey].toString().toLowerCase().includes(value.toLowerCase()))
                this.setState({
                    pseudoHistory: filteredData
                })
            } else {
                this.setState({
                    pseudoHistory: history,
                })
            }
        })
    }

    handleLogout = () => {
        const { history } = this.props
        const token = localStorage.getItem('AT')
        if (token) {
            this.setState({loggingOut: true}, () => {
                const config = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                };
                API
                    .get('api/user/logout', config)
                    .then(() => {
                        localStorage.removeItem('AT')
                        history.replace('/login')
                    })
                    .catch(() => {
                        localStorage.removeItem('AT')
                        history.replace('/login')
                    });
            })
        } else {
            history.replace('/login')
        }
    }

    render() {
        const { fetchingHistory, pseudoHistory, selectedUser: { name, email, address, action }, confirmActionModalVisible, loading, currentPage, rowsPerPage, activeFilter, searchKey, searchQuery, loggingOut } = this.state
        const { history } = this.props
        const select = (
            <Select defaultValue="email" onSelect={(value) => this.setState({searchKey: value})}>
                <Select.Option value="email">Email</Select.Option>
                <Select.Option value="address">Address</Select.Option>
            </Select>
        )
        const filterMenu = (
            <Menu>
                <MenuItem className={`${activeFilter === 'all' ? 'active' : ''}`} onClick={() => this.setFilter('all')}>
                    All
                </MenuItem>
                <MenuItem className={`${activeFilter === 'approved' ? 'active' : ''}`} onClick={() => this.setFilter('approved')}>
                    Approved
                </MenuItem>
                <MenuItem className={`${activeFilter === 'pending' ? 'active' : ''}`} onClick={() => this.setFilter('pending')}>
                    Pending
                </MenuItem>
                <MenuItem className={`${activeFilter === 'denied' ? 'active' : ''}`} onClick={() => this.setFilter('denied')}>
                    Denied
                </MenuItem>
                <MenuItem className={`${activeFilter === 'whitelisted' ? 'active' : ''}`} onClick={() => this.setFilter('whitelisted')}>
                    Whitelisted
                </MenuItem>
                <MenuItem className={`${activeFilter === 'non-whitelisted' ? 'active' : ''}`} onClick={() => this.setFilter('non-whitelisted')}>
                    Non-Whitelisted
                </MenuItem>
            </Menu>
        )
        return (
            <>
                <div className="header" style={{textAlign: 'left', padding: '1.5rem 4.5rem', marginBottom: '1.5rem'}}>
                    <span className="logo">Bollycoin</span> | Admin Panel
                </div>
                <div className="home-section">
                    <button className="home-button" onClick={this.handleLogout} disabled={loggingOut}>
                        {loggingOut ? (
                            <CircularProgress size={24} style={{color: '#000'}} />
                        ) : (
                            <FiLogOut size={24} color="#333" />
                        )}
                    </button>
                    <div className="dashboard">
                        <div className="flex-spaced-container">
                            <div className="heading">Whitelist requests <IoRefresh color="#000" style={{cursor: 'pointer', position: 'relative', top: '3px'}} onClick={this.fetchWhitelistHistory} /></div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Input
                                    placeholder={`Search by ${searchKey}`}
                                    size="large"
                                    allowClear
                                    addonBefore={select}
                                    suffix={<IoSearch />}
                                    value={searchQuery}
                                    onChange={this.handleSearch}
                                    style={{marginRight: '8px'}}
                                />
                                <Dropdown trigger="click" placement="bottomRight" overlay={filterMenu}>
                                    <IconButton>
                                        <IoFilter color="#000" />
                                    </IconButton>
                                </Dropdown>
                            </div>
                        </div>
                        {fetchingHistory ? (
                            <div className="history-loading-container">
                                <CircularProgress size={26} style={{color: '#000'}} />
                            </div>
                        ) : (
                            pseudoHistory.length > 0 ? (
                                <>
                                    <TableContainer>
                                        <Table size="medium">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>E-mail</TableCell>
                                                    <TableCell>Wallet Address</TableCell>
                                                    <TableCell>Investment (USD)</TableCell>
                                                    <TableCell>Message</TableCell>
                                                    <TableCell>Whitelisted</TableCell>
                                                    <TableCell style={{textAlign: 'right'}}>Whitelist Status / Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    pseudoHistory.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map(({ _id, name, email, address, isWhitelisted, usdValue, status, createdAt, message }) => (
                                                        <TableRow key={_id}>
                                                            <TableCell>{new Date(createdAt).toLocaleDateString()}</TableCell>
                                                            <TableCell>{name}</TableCell>
                                                            <TableCell>{email}</TableCell>
                                                            <TableCell>
                                                                <a
                                                                    href={`https://etherscan.io/address/${address}`}
                                                                    target="_blank"
                                                                    rel="noreferrer noopener"
                                                                >
                                                                    {`${address}`.substring(0,6) + '...' + `${address}`.substring(37,42)}
                                                                </a>
                                                            </TableCell>
                                                            <TableCell>{usdValue}</TableCell>
                                                            <TableCell>{message === 'null' ? '-' : message}</TableCell>
                                                            <TableCell>{isWhitelisted ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell style={{textAlign: 'right'}}>
                                                                {status !== 'Pending' ? (
                                                                    <Tag
                                                                        color={status === 'Approved' ? 'green' : status === 'Pending' ? 'processing' : 'red'}
                                                                    >
                                                                        {status}
                                                                    </Tag>
                                                                ) : (
                                                                    <div className="whitelist-actions">
                                                                        <button className="approve-button" onClick={() => this.setActiveUser(_id, 'Approve')}>Approve <FiCheck /></button>
                                                                        <button className="deny-button" onClick={() => this.setActiveUser(_id, 'Deny')}>Deny <FiX /></button>
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {pseudoHistory.length > 5 && (
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 20, 35]}
                                            component="div"
                                            count={pseudoHistory.length}
                                            rowsPerPage={rowsPerPage}
                                            labelRowsPerPage="Requests per page"
                                            labelDisplayedRows={this.handleRowLabel}
                                            page={currentPage}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage} 
                                        />
                                    )}
                                </>
                            ) : (
                                <Empty description="No requests found" style={{margin: '4rem auto 2.5rem'}} />
                            )
                        )}
                    </div>
                </div>  
                <WhitelistActionModal
                    visible={confirmActionModalVisible}
                    name={name}
                    email={email}
                    address={address}
                    action={action}
                    onCancel={this.clearActiveUser}
                    onConfirm={() => {
                        if (localStorage.getItem('AT')) {
                            action === 'Approve' ? this.approveUser() : this.rejectUser()
                        } else {
                            notification['error']({
                                message: 'Your session has been expired. Please log in again',
                                onClose: () => history.replace('/')
                            })
                        }
                    }}
                    loading={loading}
                />
            </>
        )
    }
}

export default withRouter(Home);